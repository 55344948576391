"use client"
import React from 'react'
import Image from 'next/image'
import HeaderPhoto from "../../../public/Header.png"
import AppHeaderPhoto from "../../../public/AppHeader.png"
import Header2 from "../../../public/Headersmall.png"
import Phonebg from "../../../public/main.png"
import { RiFacebookCircleLine } from "react-icons/ri";
import { Oswald, Roboto, Fredoka } from 'next/font/google';
import { SearchBar } from '../SearchBar/searchBar'
import Navbar from '../navbar'
import { CSSProperties, useState, useEffect } from 'react';
import { Button } from '../ui/button'
import Link from 'next/link'
import { FaFacebook } from 'react-icons/fa'
import LoadingAnimation from '../LoadingAnimation/LoadingAnimation'
//import {loadingAnimation} from '../../../public/Animation - 1716200300959.gif'

import { useSelector } from 'react-redux';
import { useAppSelector } from "@/redux/store";
import { ShoppingCart } from "iconsax-react";
import { useRouter, usePathname } from 'next/navigation'

// import { BeforeInstallPromptEvent } from './events';
const fredoka = Fredoka({ subsets: ['latin'] })
const oswalds = Oswald({ subsets: ['latin'] })
const roboto = Roboto({ weight: ["400", "700"], subsets: ['latin'] });
interface Props { }
export const Header = (_props: Props) => {

    const router = useRouter();

    const [isInstalled, setIsInstalled] = useState(false);
    const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const beforeInstallPromptHandler = (event: any) => {
            event.preventDefault();
            setDeferredPrompt(event);
        };

        window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler);

        return () => {
            window.removeEventListener('beforeinstallprompt', beforeInstallPromptHandler);
        };
    }, []);

    const onClickHandler = () => {
        if (deferredPrompt) {
            deferredPrompt.prompt().then((result: any) => {
                if (result.outcome === 'accepted') {
                    console.log('User accepted the A2HS prompt!');
                } else {
                    console.log('User dismissed the A2HS prompt!');
                }
                setDeferredPrompt(null);
            });
        }
    };

    const handleCartClick = () => {
        router.push("/cart");
    }


    const isPWA = useAppSelector((state: any) => state.pwacheckReducer.isPWA);
    console.log("check PWA", isPWA);


    return (
        <div>
            {
                isPWA ? (
                    
                    <div className='header-container border-t border-[#397BEA]'>
                        <style jsx>{`
                        .header-container {
                        background-image: linear-gradient(to right, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${AppHeaderPhoto.src});
                        background-size: cover;
                        background-position: center;
                        height: calc(20vh + env(safe-area-inset-top));
      padding-top: env(safe-area-inset-top);
                        }
                    `}</style>
                        <div className='flex justify-between items-center px-2 mt-2'>
                            <span className={`text-white text-3xl font-medium leading-[28.8px] ${fredoka.className}`}>Canada's favourite</span>
                            <div onClick = {handleCartClick} className="bg-white rounded-full flex justify-center items-center w-12 h-12">
                                <ShoppingCart size="32" color="#FF801F" variant="Bulk" />
                            </div>
                        </div>
                        <div className={`text-white text-3xl font-medium leading-[28.8px] px-2 mt-1 ${fredoka.className}`}>"no crazy fees" site</div>
                        <div className='pt-8'>
                            <SearchBar className='bg-white mx-8 rounded-full' />
                        </div>
                        <div>

                        </div>
                    </div>
                ) : (
                    <div>
                        <style jsx>{`
                .header-container {background-image: linear-gradient(to right, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${HeaderPhoto.src});}       
                .phone-container {background-image: url(${Phonebg.src})}
                @media (max-width: 648px) { .header-container { background-image: url(${HeaderPhoto.src});}}
            `}
                        </style>
                        <div className={`bg-cover bg-center bg-no-repeat max-w-full ${loading ? 'bg-white' : 'header-container'}`}>
                            <div className='flex flex-col items-center gap-[24px]'>
                                <div className='w-full'>
                                    <div className='bg-[#201F5C] gap-2 py-2 w-full flex  md:flex-row flex-col items-center justify-center'>
                                        <h1 className='font-normal text-[1rem]  max-[368px]:text-[0.7rem] text-white'>Price may be higher or lower than face value.</h1>
                                        <a
                                            href="https://www.facebook.com/groups/KarmaTickets"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="flex items-center gap-1 text-[1rem] max-[368px]:text-[0.7rem] text-orange-500"
                                        >
                                            Connect with us on Facebook
                                            <FaFacebook className="text-orange-500" />
                                        </a>
                                    </div>
                                    <Navbar loading={loading} setLoading={setLoading} className={`bg-[#3BC3F2] w-full py-4`} />
                                </div>
                                {
                                    loading ? <div className='flex justify-center items-center h-[60vh]'>
                                        <LoadingAnimation />
                                    </div> : (
                                        <div className='grid grid-col-3 grid-flow-col md:gap-10'>
                                            {/* <div className=' w-[150px] hidden md:flex'></div> */}
                                            <div className='flex-col justify-center items-center gap-4 px-4 h-full md:py-6'>
                                                <div className='flex-col gap-4 md:gap-16 justify-center items-center'>
                                                    <div className={`flex flex-col items-center justify-center text-sm sm:text-xl font-medium text-white ${fredoka.className}`}>
                                                        <h1 className='leading-normal md:max-w-[867px] lg:text-3xl'>CANADA&apos;S FAVOURITE “NO CRAZY FEES” SITE</h1>
                                                        <h1 className='leading-normal md:max-w-[867px] lg:text-3xl'>SPECIALIZING IN GAME DAY DEALS!</h1>
                                                    </div>
                                                    {/* <div className={`md:hidden flex-col items-center flex justify-center text-lg text-white font-medium leading-normal max-w-[356px] sm:max-w-[300px] ${oswalds.className}`}>
                                            <h1>YOUR PASSPORT TO SPORTS,</h1>
                                            <h1>CONCERTS, THEATRE, AND BEYOND</h1>
                                        </div> */}
                                                    <div className='w-full pt-4'>
                                                        <SearchBar className='lg:w-[647px] md:w-[400px] w-full rounded-full flex flex-col relative gap-10' className1="bg-[#3BC3F2] flex items-center justify-center h-full text-white" styles={{ divstyle: { borderRadius: "41px", display: "flex", flexDirection: "row-reverse" } }} setLoading={setLoading} />
                                                    </div>
                                                </div>

                                                <div>
                                                    <div className='flex items-center flex-col  py-4 justify-center'>
                                                        {/* {isInstalled ? (
                                                <span>App Installed</span>
                                            ) : (
                                                <button
                                                    type='button'
                                                    className='text-white bg-[#FF801F] font-medium flex hover:bg-[#F09855] rounded-lg text-sm px-5 py-2.5 '
                                                    onClick={onClickHandler}
                                                >
                                                    INSTALL OUR APP
                                                </button>
                                            )} */}
                                                        <div className=' bg-orange-500 backdrop-blur-[100px] rounded fill-[#000000] bg-black flex flex-col px-4 py-2 items-center'>
                                                            <h1 className='text-base sm:text-lg font-bold  text-[#27276D]'>Have a question ?</h1>
                                                            {/* <h1 className='text-[10px] sm:text-base text-[#397BEA] font-extrabold'>PARTICIPATE IN OUR RAZZES HERE</h1> */}
                                                            <button className='text-[10px] sm:text-base text-white  font-bold ' onClick={() => setLoading(true)}>  <a href='/faq' className='underline underline-offset-1'>Please Read Our FAQs</a></button>
                                                        </div>
                                                    </div>

                                                </div>
                                                {/* <div className='flex items-center sm:text-base text-sm pb-4 justify-center'>
                                        <button className='text-white font-semibold mb-4 md:mb-0'>Need Help?  <a href='tel:4373702833' className='underline underline-offset-1'>(437) 370-2833</a></button>
        
                                    </div> */}
                                            </div>
                                            {/* <div className="bg-no-repeat z-90 w-[60%] h-[60%] md:flex items-start justify-start hidden" >
                                    <div className='absolute '>
                                        <Image src="/Star1.svg" alt="Logo" width={100} height={100} />
                                    </div>
                                    <div className='text-white  text-center items-center justify-center relative text-[12px]  w-full h-[60%] mx-4 mt-4' style={{
        
                                    }}>CHECK OUT OUR FAQ&apos;S</div>
                                </div> */}
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        {/* <div className='flex md:flex-row flex-col justify-around md:py-6 py-3 px-2 md:px-0 items-center gap-4 md:gap-0' style={{ background: "linear-gradient(270deg, #0C35E1 -2.97%, #BA0021 132.18%)" }}>
                <div className={`text-[20px] max-[400px]:text-[15px] text-white max-[400px]:leading-5 lg:text-[23px] ${oswalds.className}`}>
                    <h1>JOIN CANADA’S LARGEST & MOST TRUSTED SOCIAL MEDIA PLATFORM FOR TICKETS!</h1>
                    <h1>FOR TICKETS!</h1>
                </div>
                <div className='flex md:flex-row flex-col items-center gap-[0.5rem] rounded-full '>
                    <div className='flex flex-row items-center gap-[0.5rem] rounded-full '>
                        <a href="https://www.facebook.com/groups/KarmaTickets" title='FaceBook' target="_blank" rel="noopener noreferrer">
                            <div className='bg-[#FF801F] hover:bg-[#F09855] flex flex-row rounded-xl gap-[0.5rem] max-sm:px-2 max-sm:py-1 lg:px-8 lg:py-4 px-6 py-3 items-center'>
                                <span className='text-[12px] text-white lg:text-[19px]'>Connect With Us</span>
                                <FaFacebook className='text-[#014DB5] h-5 w-5 md:h-7 md:w-7' />
                            </div>
                        </a>
                    </div>
                </div>
            </div> 
        */}
                        {/* <div className={`md:hidden md:px-4 lg:px-0 flex flex-col md:h-[192px] py-4 md:py-0 relative bg-[#134A8E] md:flex-row items-center justify-evenly gap-4 md:gap-0 max-md:px-2 text-white text-xl font-medium`}>
                <div className='flex flex-col gap-4'>
                    <h1 className={`text-white font-bold text-xl md:text-[25px] lg:text-[33px] ${fredoka.className}`}>DOWNLOAD THE <span className='text-[#397BEA]'>NEW</span> KARMA TICKETS APP NOW!</h1>
                    <div className={`${fredoka.className} flex flex-col`}>
                        <div className='text-white text-sm md:text-[16px] leading-4'>Stay up to date with <span className='text-[#FF6F00]'>“Game Day Deals”</span> messages sent directly to your phone</div>
                        <div className='text-white text-sm md:text-[16px] leading-4'>with our game changing push notifications. Get started today!</div>
                    </div>
                </div>
                <div className='absoulte  md:flex hidden items-center justify-center'>
                    <Image src="/phone.png" alt='Phone' width={182} height={257} className='drop-shadow-2xl ' />
                </div>
                <div className={`${fredoka.className}`}>
                    {isInstalled ? (
                        <span>App Installed</span>
                    ) : (
                        <div className='text-white bg-[#397BEA] font-medium flex flex-col justify-center items-center hover:bg-[#80a6e8] rounded-[16px] text-sm md:px-4 md:py-2 p-2'
                            onClick={onClickHandler}>
                            <h1>DOWNLOAD</h1>
                            <h1>OUR APP HERE</h1>
                        </div>
                    )}
                </div>

            </div> */}


                        <div className={`md:px-4 lg:px-0 flex flex-col md:h-[192px] py-4 md:py-0 relative bg-[#27276e] md:flex-row items-center justify-evenly gap-4 md:gap-0 max-md:px-2 text-white text-xl font-medium`}>
                            <div className='sm:w-[40%] lg:w-1/2 flex flex-col gap-4'>
                                <h1 className={`text-[#3bc3f2] font-bold text-xl md:text-[25px] lg:text-[33px] ml-[16px] md:ml-0 lg:ml-0 lg:text-right ${fredoka.className} text-center md:text-left`}>
                                    COMING SOON!
                                </h1>
                                <div className={`${fredoka.className} flex flex-col`}>
                                    <div className='text-white text-sm md:text-[16px] leading-4 text-center md:text-left lg:text-right'>
                                        Stay up to date with <span className='text-[#FF6F00]'>“Game Day Deals”</span> messages sent directly to your phone with our game changing push notifications. Get started today!
                                    </div>
                                </div>
                            </div>



                            <div className='absoulte  md:flex hidden items-center justify-center'>
                                <Image src="/iPhone.png" alt='Phone' width={182} height={257} className='drop-shadow-2xl' />
                            </div>
                            <div className={`${fredoka.className}`}>
                                {isInstalled ? (
                                    <span>App Installed</span>
                                ) : (
                                    <div>
                                        <div className='md:hidden text-white bg-[#3BC3F2] font-medium flex flex-col justify-center items-center hover:bg-[#80a6e8] rounded-[16px] text-sm md:px-4 md:py-2 p-2'
                                        // onClick={onClickHandler}
                                        >
                                            {/* <h1>DOWNLOAD</h1>
                                <h1>OUR APP HERE</h1> */}
                                            <h1>APP LAUNCH SPRING 2025</h1>
                                        </div>
                                        <div className='hidden md:block text-white bg-[#3BC3F2] font-medium rounded-[16px] text-xl md:px-4 md:py-2 p-2'>
                                            <h1>APP LAUNCH SPRING 2025</h1>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* <div className='flex flex-col md:flex-row justify-center max-md:px-2 max-md:gap-[1rem] max-lg:gap-[3rem] max-xl:gap-[4rem] gap-[9rem] py-[24px] text-white font-semibold font-oswald bg-[#397BEA]'>
                <div className={`${oswalds.className} font-semibold max-[400px]:text-[15px] max-[400px]:leading-5 text-[21px] lg:text-[28px] leading-[120%] text-xl text-white`}>
                    <h1>RAZZ OFFERS: PURCHASE TICKETS & SPORTS MEMORABILIA FOR A </h1>
                    <h1>FRACTION OF THE PRICE</h1>
                </div>
                <div className='flex flex-row items-center gap-[0.5rem] rounded-full  '>
                    <a href="https://razilia.com/" title='Razzal' target="_blank" rel="noopener noreferrer">   <button className='text-[12px] bg-[#FF801F] hover:bg-[#F09855]  max-sm:px-2 max-sm:py-1 lg:px-8 lg:py-4 px-6 py-3  rounded-full lg:text-[19px]'>BUY RAZZ HERE</button></a>
                  
                </div>
            </div>*/}
                    </div>
                )
            }
        </div>

    )
}
