import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const pwacheck = createSlice({
    name: 'pwacheck',
    initialState: {
        isPWA: true,
    },
    reducers: {
        UpdatePwaCheck: (state, action: PayloadAction<boolean>) => {
            state.isPWA = action.payload;
        }
    },
});

export const { UpdatePwaCheck } = pwacheck.actions;
export default pwacheck.reducer;