import { configureStore } from "@reduxjs/toolkit";
import cartReducer from './features/cart-slice'
import buyNowReducer from './features/buynow-slice'
import { TypedUseSelectorHook , useSelector } from "react-redux";

import pwacheckReducer from './features/pwacheck-slice'

export const store = configureStore({
    reducer:{
        cartReducer,
        buyNowReducer,
        pwacheckReducer,
    }
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch; 


export const useAppSelector : TypedUseSelectorHook<RootState> = useSelector;