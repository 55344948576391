"use client";
import React from 'react'
import FloatingBar from '../FloatingBar/FloatinBar';
import { useAppSelector } from "@/redux/store";

const FloatingButton = () => {
    const isPWA = useAppSelector((state: any) => state.pwacheckReducer.isPWA);
    return (
        <>
            {isPWA && <FloatingBar />}
        </>
    )
}


export default FloatingButton;