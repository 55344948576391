'use client'
import { NextPage } from 'next';
import Link from 'next/link';
import Image from 'next/image';
import Artist from "../../../public/Top-Artist.png"
import { Oswald, Roboto, Fredoka } from 'next/font/google';
import { FiArrowRight } from "react-icons/fi";
import FloatingButton from '../FloatingButton/FloatingButton';
const fredoka = Fredoka({ subsets: ['latin'] })
const oswalds = Oswald({ subsets: ['latin'] })
const roboto = Roboto({ weight: ['300'], subsets: ['latin'] })
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel"
import { useState, useEffect, Suspense } from 'react';
import LoadingAnimation from '../LoadingAnimation/LoadingAnimation';
import SkeletonCard from '../SkeletonCard/SkeletonCard';
import Loader2 from '../LoaderFile2/Loader2';

interface TopArtist {
  id: number;
  name: string;
  occurs_at: string;
  venue: {
    name: string;
  };
}

interface imageInfo {
  eventId: number;
  image_url: string;
}

interface TopArtistProps {
  lat: any;
  lon: any;
  dateRange: string;
}

function formatDate(inputDate: any) {
  // Parsing the date as UTC to avoid timezone affecting the output
  const date = new Date(inputDate);
  return date.toLocaleDateString('en-US', {
    timeZone: 'UTC',
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
}
let url: any;
if (typeof window !== "undefined") {
  url = localStorage.getItem("Frontend_API");
  // console.log(url);
}

let domain_url: any;

if (typeof window !== "undefined") {
  domain_url = localStorage.getItem("domain_url");
  // console.log(domain_url);
}

export const AllTeams: NextPage<TopArtistProps> = ({ lat, lon, dateRange }) => {
  const [topartist, setTopArtist] = useState<TopArtist[]>([]);
  const [imageInfo, setImageInfo] = useState<imageInfo[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [artistLoading, setArtistLoading] = useState(false);

  useEffect(() => {
    const fetchEvents = async () => {
      setArtistLoading(true);
      try {
        const message = `GET ${domain_url}/v9/events?order_by=events.popularity_score%20DESC;order_by=events.occurs_at%20ASC;lat=${lat};lon=${lon};within=20;`;
        console.log('TOP ARTIST ENDPOINT', message);

        const res = await fetch(`${url}/api/signature`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(message),
        });

        if (!res.ok) {
          setArtistLoading(false);
          throw new Error('Failed to fetch signature');
        }

        const data1 = await res.json();
        const requestBody = {
          signature: data1.msg,
          lat,
          lon,
          dateRange,
        };

        const response = await fetch(`${url}/api/allteams`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
          setArtistLoading(false);
          throw new Error('Failed to fetch events');
        }

        const data = await response.json();
        console.log('this is artist data: ', data);

        setTopArtist(data.data);
        setImageInfo(data.imageInfo);
        setArtistLoading(false);
      } catch (error: any) {
        setArtistLoading(false);
        setError(error.message);
      } finally {
        setArtistLoading(false);
        setLoading(false);
      }
    };

    if (lat && lon) {
      fetchEvents();
    }
  }, [lat, lon, dateRange]);

  if (loading) {
    return (
      <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 bg-white w-screen h-screen">
        <div className="w-full h-full absolute flex items-center justify-center text-black">
          <LoadingAnimation />
        </div>
      </div>
    );
  }

  if (error) return <p>{error}</p>;
  if (!artistLoading && topartist.length === 0) return null;

  const filteredWeekend = topartist.filter((performer: any) => !performer.name.includes("Rescheduled")).slice(0, 15);
  const filteredImageInfo = filteredWeekend.slice(0, 15).map(performer => {
    const matchedInfo = imageInfo.find(info => info?.eventId === performer.id);
    return matchedInfo ? matchedInfo : { eventId: performer.id, image_url: Artist };
  });

  return (
    <div className="w-full flex justify-center">
      <div className="w-full flex flex-col gap-2 justify-start max-[976px]:px-2 min-[1200px]:w-[1170px] min-[922px]:w-[970px] min-md:w-[750px] max-md:px-[15px] max-md:mx-auto">
        <div className="flex justify-between">
          <h1 className={`text-stone-900 md:text-3xl text-2xl font-medium uppercase leading-10 ${fredoka.className}`}>
            ALL EVENTS
          </h1>
          <div className="flex items-center">
            <Link href={`/moreevents1?lat=${lat}&lon=${lon}&dateRange=Any%20Date`}>
              <button className="flex items-center bg-white font-medium text-black px-4 py-2 rounded-md transition-colors duration-300 ease-in-out hover:bg-gray-300 sm:w-auto sm:px-6 sm:py-3" onClick={() => setLoading(true)}>
                <span className="mr-2 hidden md:inline-block">See more artists</span>
                <FiArrowRight className="w-4 h-4" />
              </button>
            
            </Link>
          </div>
        </div>
        {
          artistLoading ? (
            <Carousel opts={{ align: "center" }} className="w-full flex justify-center items-center">
              <CarouselContent>
                {[...Array(4)].map((_, index) => (
                  <CarouselItem key={index} className="sm:basis-1/2 md:basis-1/3 lg:basis-1/4">
                    <SkeletonCard />
                  </CarouselItem>
                ))}
              </CarouselContent>
            </Carousel>
          ) : (
            <>
              <Carousel opts={{ align: "start" }} className="w-full overflow-x-scroll md:overflow-x-hidden">
                <CarouselContent className='flex justify-start items-start'>
                  {filteredWeekend.map((performer: any, index) => (
                    <CarouselItem key={performer?.id} className="basis-[75%] md:basis-1/3 lg:basis-1/4">
                      <Link href={`/viewtickets1/${performer?.id}`}>
                        <div className={`flex flex-col gap-[8px] ${roboto.className} transition duration-300 hover:shadow-sm hover:scale-[1.02] cursor-pointer`} onClick={() => setLoading(true)}>
                          <div className={`flex flex-col gap-[8px] ${roboto.className}`}>
                            <Image src={filteredImageInfo[index]?.eventId === performer?.id ? filteredImageInfo[index].image_url : Artist} alt="event" width={300} height={150} className="rounded-lg shrink-0 object-cover w-full sm:w-[300px] h-[150px]" />
                            <div className={`text-stone-500 text-base font-semibold leading-tight ${fredoka.className}`}>{performer?.name}</div>
                            <div className="text-stone-500 text-base font-normal leading-tight">{formatDate(performer?.occurs_at)}</div>
                            <div className="text-stone-500 text-base font-normal leading-tight">{performer?.venue?.name}</div>
                          </div>
                        </div>
                      </Link>
                    </CarouselItem>
                  ))}
                </CarouselContent>
                {
                  topartist?.length > 4 ? (
                    <>
                      <CarouselPrevious className='hidden sm:block p-6 py-2 pl-2 rounded-full cursor-pointer' />
                      <CarouselNext className='hidden sm:block p-6 py-2 pl-2 rounded-full cursor-pointer' />
                    </>
                  ) : topartist?.length === 4 && (
                    <div className='hidden md:block lg:hidden'>
                      <CarouselPrevious className='hidden sm:block p-6 py-2 pl-2 rounded-full cursor-pointer' />
                      <CarouselNext className='hidden sm:block p-6 py-2 pl-2 rounded-full cursor-pointer' />
                    </div>
                  )
                }
              </Carousel>
            </>
          )
        }
          {/* <FloatingButton/> */}
      </div>
    </div>


  );
};

// export default TopArtist;
