"use client";
import React from 'react'
import Footer from '@/components/footer/page';
import BottomAppNavBar from '@/components/BottomAppNavbar/BottomAppNavbar';
import { useAppSelector } from "@/redux/store";

const BottomFooter = () => {
    const isPWA = useAppSelector((state: any) => state.pwacheckReducer.isPWA);
    return (
        <>
            {isPWA ? <BottomAppNavBar /> : <Footer />}
        </>
    )
}

export default BottomFooter;