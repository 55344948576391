'use client'
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/navigation';
import { FaQuestionCircle, FaFileAlt, FaLock, FaPhoneAlt, FaTimes } from 'react-icons/fa';
import { motion } from 'framer-motion';


const FloatingBar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const router = useRouter();

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };

    const handleFAQclick = () => {
        router.push('/faqapp');
    };

    const handleTermsAndConditions = () => {
        router.push('/terms-and-conditions');
    };

    const handleContactClick = () => {
        router.push('/contactus');
    };

    return (
        <div className="relative z-50">
            <button
                onClick={toggleMenu}
                className="fixed bottom-28 right-4 bg-orange-500 text-white p-3 rounded-full shadow-lg flex items-center justify-center z-50"
            >
                {isOpen ? <FaTimes className="h-[18px] w-[18px]" /> : <FaQuestionCircle className="h-6 w-6" />}
            </button>
            {isOpen && (
                <>
                    <motion.div
                        initial={{ scale: 0, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        transition={{ duration: 0.3 }}
                        className="fixed bottom-40 right-4 bg-white rounded-full shadow-lg w-44 p-4 flex items-center z-50"
                    >
                        <FaFileAlt className="h-5 w-5 mr-2 text-orange-500" />
                        <button className="flex-1 text-left text-orange-500 hover:text-orange-700" onClick={handleFAQclick}>
                            FAQ's
                        </button>
                    </motion.div>
                    <motion.div
                        initial={{ scale: 0, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        transition={{ duration: 0.3, delay: 0.1 }}
                        className="fixed bottom-56 right-4 bg-white rounded-full shadow-lg w-58 p-4 flex items-center z-50"
                    >
                        <FaFileAlt className="h-5 w-5 mr-2 text-orange-500" />
                        <button className="flex-1 text-left text-orange-500 hover:text-orange-700" onClick={handleTermsAndConditions}>
                            Terms & Conditions
                        </button>
                    </motion.div>
                    <motion.div
                        initial={{ scale: 0, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        transition={{ duration: 0.3, delay: 0.2 }}
                        className="fixed bottom-72 right-4 bg-white rounded-full shadow-lg w-44 p-4 flex items-center z-50"
                    >
                        <FaPhoneAlt className="h-5 w-5 mr-2 text-orange-500" />
                        <button className="flex-1 text-left text-orange-500 hover:text-orange-700" onClick={handleContactClick}>
                            Contact Us
                        </button>
                    </motion.div>
                </>
            )}
            {isOpen && <div onClick={closeMenu} className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>}
        </div>
    );
};

export default FloatingBar;
