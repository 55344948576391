"use client";
import { ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { UpdatePwaCheck } from '@/redux/features/pwacheck-slice';

interface PWAStatusCheckerProps {
  children: ReactNode;
}

function PWAStatusChecker({ children }: PWAStatusCheckerProps) {
  const dispatch = useDispatch();

  useEffect(() => {
    function checkPWAStatus() {
      console.log("inside checkPWAStatus");
      const isStandalone = window.matchMedia('(display-mode: standalone)').matches;

      if (isStandalone) {
        console.log("its pwa");
        dispatch(UpdatePwaCheck(true));
        localStorage.setItem('isPWA', 'true');
      } else {
        console.log("not pwa");
        dispatch(UpdatePwaCheck(false));
        localStorage.setItem('isPWA', 'false');
      }
    }

    checkPWAStatus();
  }, [dispatch]);

  return <>{children}</>; // Render children inside
}

export default PWAStatusChecker;
