'use client'
import React from 'react'
import { useEffect, useState } from 'react';
import { Home } from "iconsax-react";
import { Coin1 } from "iconsax-react";
import { Ticket } from "iconsax-react";
import { Category } from "iconsax-react";
import { useRouter } from 'next/navigation';
import Image from 'next/image';

interface User {
    firstname: string;
    lastname: string;
    email: string;
}


const BottomAppNavbar = () => {
    

    const [user, setUser] = useState<User | null>(null);

    useEffect(() => {
        // Retrieve user from local storage
        const storedUser = localStorage.getItem('users');

        if (storedUser) {
            try {
                // Parse storedUser from JSON string to object
                const parsedUser = JSON.parse(storedUser);

                // Extract required fields
                const userData: User = {
                    firstname: parsedUser.firstname,
                    lastname: parsedUser.lastname,
                    email: parsedUser.email,
                };

                // Set the extracted data in the state
                setUser(userData);
                console.log('User data set successfully:', userData);
            } catch (error) {
                console.error('Failed to parse stored user:', error);
            }
        }
    }, []);

    const router = useRouter();
    const handleCategoryClick = () => {
        router.push('/categoriesapp'); // Navigate to the CategoryApp page
    };

    const handleHomeClick = () => {
        router.push('/');
    }
    //  const handleRazzalClick=()=>{
    //     router.push('https://razilia.com');

    //  }
    const handleTicketClick = () => {
        router.push('/my-tickets');

    }

    const handleProfileClick = () => {
        router.push('/homeprofile');
    }
    const [currentPath, setCurrentPath] = useState('');

    useEffect(() => {
        setCurrentPath(window.location.pathname); // Get the current URL path
    }, []);

    return (
        <>
            <br />
            <br />
            {/* <br />
            <br /> */}
            
            <div className='bg-[#397BEA] flex justify-between items-center px-4 h-20 fixed bottom-0 w-full'>
            <div className="flex flex-col items-center">
                <Home size="32" color={currentPath === '/' ? '#FF994C' : '#FFFFFF'} variant="Bold" onClick={handleHomeClick} />
                <span className="text-white text-sm">Home</span>
            </div>
            <div className="flex flex-col items-center">
                <a href="https://razilia.com" target="_blank" rel="noopener noreferrer">
                    <Coin1 size="32" color="#FFFFFF" variant="Bulk" />
                    <span className="text-white text-sm">Razz</span>
                </a>
            </div>
            <div className="flex flex-col items-center">
                <Ticket size="32" color={currentPath === '/my-tickets' ? '#FF994C' : '#FFFFFF'} variant="Bold" onClick={handleTicketClick} />
                <span className="text-white text-sm">My Tickets</span>
            </div>
            <div className="flex flex-col items-center" onClick={handleCategoryClick}>
                <Category size="32" color={currentPath === '/categoriesapp' ? '#FF994C' : '#FFFFFF'} variant="Bold" />
                <span className="text-white text-sm">Categories</span>
            </div>
            <div className="flex flex-col items-center" onClick={handleProfileClick}>
                <div className="flex items-center justify-center bg-white text-black rounded-full w-8 h-8">
                    {user && user.firstname ? user.firstname.charAt(0).toUpperCase() :
                        <div className="relative flex items-center justify-center w-[60px] h-[40px] bg-[#397BEA] text-white text-2xl font-bold rounded-full">
                            <Image className="relative" alt="" width={25} height={25} src="/Guesticon.svg" />
                        </div>
                    }
                </div>
                <span className="text-white text-sm">Profile</span>
            </div>
        </div>
        </>
    )
}

export default BottomAppNavbar;