// components/CityDateFilters.tsx
"use client";
import React, { useEffect, useState } from "react";
import { useRouter, useSearchParams } from "next/navigation";
import { Fredoka } from "next/font/google";
import CitySearchBar from "./CitySearchBar/CitySearchBar";
import { useAppSelector } from "@/redux/store";

const fredoka = Fredoka({ subsets: ["latin"] });

interface CityDateFiltersProps {
  dateRange: string;
  setLat: (lat: number) => void;
  setLon: (lon: number) => void;
  setDateRange: (dateRange: string) => void;
  setCity: (city: string) => void;
}

const CityDateFilters: React.FC<CityDateFiltersProps> = ({
  dateRange,
  setLat,
  setLon,
  setDateRange,
  setCity,
}) => {
  const [mounted, setMounted] = useState(false);

  const isPWA = useAppSelector((state: any) => state.pwacheckReducer.isPWA);

  useEffect(() => {
    setMounted(true);
  }, []);

  const handleCitySelect = (
    city: string,
    selectedLat: number,
    selectedLon: number
  ) => {
    const upperCaseCity = city.toUpperCase();
    setCity(upperCaseCity);
    setLat(selectedLat);
    setLon(selectedLon);

    localStorage.setItem("city", upperCaseCity);
    localStorage.setItem("lat", selectedLat.toString());
    localStorage.setItem("lon", selectedLon.toString());
  };

  const handleDateRangeSelect = (range: string) => {
    setDateRange(range);
  };

  if (!mounted) {
    return null;
  }

  return (
    <div
      className={`flex flex-col px-4 sm:px-10 lg:px-44 gap-2 max-w-screen-2xl mx-auto ${fredoka.className} justify-start`}
    >

      {
        isPWA ? (
          <div className="flex justify-between items-center">
          <div>
            <div className="text-stone-950 font-medium text-2xl">WHAT&apos;S </div>
            <div className="text-stone-950 font-medium text-2xl">HAPPENING IN</div>
          </div>
          <div
            className={`inline-flex border-2 border-gray ${fredoka.className} rounded-full items-center justify-center relative text-left text-base flex-row text-blue-600 font-medium cursor-pointer px-4 py-1 overflow-hidden ml-4`}
            style={{ maxWidth: '150px', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
          >
            <CitySearchBar onSelect={handleCitySelect} />
          </div>
        </div>
        ) : (
          <div className="flex flex-col w-full justify-start md:flex-row items-center gap-2.5">
            <div className="text-stone-950 max-[284px]:text-[14px] max-[310px]:text-[16px] max-[370px]:text-lg max-[395px]:text-xl md:text-3xl sm:text-xl text-2xl font-medium leading-10">
              WHAT&apos;S HAPPENING IN
            </div>
            <div
              className={`inline-flex border-2 border-gray pd-4 ${fredoka.className} rounded-md items-center justify-center relative text-left max-[284px]:text-[14px] max-[310px]:text-[16px] max-[370px]:text-lg max-[395px]:text-xl md:text-3xl sm:text-xl text-2xl flex-row text-blue-600 font-medium uppercase cursor-pointer pl-5`}
            >
              <CitySearchBar onSelect={handleCitySelect} />
            </div>
          </div>
        )
      }

      {
        !isPWA && (
          <div className="text-orange-500 text-sm md:text-lg font-normal leading-tight text-center md:text-left">
            Click the blue city name to type and search any major city worldwide.
          </div>
        )
      }

      <div className="py-8 gap-2 flex flex-row overflow-x-auto scrollbar-track-gray-100 scrollbar-thumb-gray-700 scroll-m-1">
        {["Next 7 Days", "This Weekend", "This Month"].map((filter) => (
          <button
            key={filter}
            // className={`p-1 min-w-[120px] sm:min-w-[160px] rounded-3xl border ${dateRange === filter
            //   ? "text-yellow-500 hover:text-yellow-500 bg-orange-50"
            //   : "text-neutral-400 hover:text-yellow-500"
            //   } m-0 whitespace-nowrap`}
            className={`p-1 min-w-[120px] sm:min-w-[160px] rounded-3xl border ${dateRange === filter
              ? (isPWA
                ? "text-white bg-[#FF801F]"  // When isPWA is true
                : "text-yellow-500 hover:text-yellow-500 bg-orange-50")  // Default when isPWA is false
              : (isPWA
                ? "bg-[#FFF2E9] text-black hover:text-yellow-500"  // When isPWA is true and not selected
                : "text-neutral-400 hover:text-yellow-500")  // Default when not selected
              } m-0 whitespace-nowrap`}

            onClick={() => handleDateRangeSelect(filter)}
          >
            <div
              className={`${dateRange === filter ? (isPWA ? "text-white" : "text-yellow-500") : (isPWA ? "text-black" : "text-neutral-400")
                } hover:text-yellow-500 text-sm md:text-lg font-semibold leading-snug`}
            >
              {/* {filter.toUpperCase()} */}
              {isPWA ? filter : filter.toUpperCase()}
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

export default CityDateFilters;
